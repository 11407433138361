

<template>
    <div id="app">
    </div>
</template>

<script>

export default {
    name: 'App',    
    data() {
      return {
        user: null
      };
    },
    mounted () {
        document.title = "Audioloom";
    }
};

</script>

<style>
html {
    background: #161617;
    background-image: url('../assets/temp-bg.jpg');
    background-size: cover;
    height: 100vh;
    /* background image always centered */
    background-position: center;
    background-repeat: no-repeat;
    color: #FEFEFE;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 600px){
        background-position: 47%;
    }
}


</style>